.lay-master-set {
    margin-bottom: 65px;
}

.lay-master-set .get_api_management {
    margin-left: 0;
    margin-bottom: 20px;
}


.lay-master__table table tr td {
    height: 50px;
    background: #fff;
    text-align: center;
    vertical-align: middle;
}

.col-user-cot1 {
    width: 20%;
}

.col-user-cot2 {
    width: 25%;
}

.col-user-cot3 {
    width: 20%;
}

.col-user-cot4 {
    width: 25%;
}

.col-user-cot5 {
    width: 10%;
}



@media (max-width: 767px) {
    .display-main {
        padding-top: 100px;
       
    }
}

@media (max-width: 990px) and (max-height: 560px) and (orientation: landscape) {
    .display-main {
        padding-left: 80px;
        padding-top: 100px;
    }
}