.lay-search-graph__form__col1 ul li, 
.lay-search-graph__form__col2 ul li, 
.lay-search-graph__form__col3 ul li {
    width: 100%; 
}

.mod-form-graph__set dl dd {
    width: 100%; 
}


.lay-search-graph__form__col2 {
    margin-bottom: 24px;
}

/* .lay-search-graph__form__col3 ul {
    display: flex;
    justify-content: space-between; 
    list-style: none;
    padding: 0;
    width: 100%;
}

.lay-search-graph__form__col3 li {
    flex-grow: 1;
    margin-right:30px;
} */
 
.lay-search-graph__form__col3 ul {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
}

.lay-search-graph__form__col3 li {
    flex-grow: 1; 
    margin-right: 30px; 
    min-width: 0; 
}

.lay-search-graph__form__col3 li:last-child {
    margin-right: 0; 
}

.lay-search-graph__form__col3 {
    margin-bottom: 16px;
}

ul {
    margin-bottom: 0;
}

.lay-search-graph__form__col2 ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0; 
    width: 100%; 
}

.lay-search-graph__form__col2 li {
    flex-grow: 1; 
    margin-right: 10px; 
}

.lay-search-graph__form__col2 li:last-child {
    margin-right: 0;  
}

/* .lay-search-graph__form__col3 ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    list-style: none;
} */

.mod-form-graph__set dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 0 !important;
}

.mod-form-graph__set dl dt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 120px; 
    height: 38px;
    background-color: #777777;
    font-size: 15px;
    font-weight: normal !important;
    color: #fff;
}

.mod-form-graph__set dl dd {
    flex: 1; 
    align-items: center;
}

.mod-form-graph__text input[type="text"] {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 38px;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff !important;
}

.btn_facilitylist {
    transform: translateX(-10px);
    color: #008eab;
    font-size: 17px;
    line-height: 30px;
    width: 100px;
    height: 35px;
    border-radius: 23px;
    background-color: #fff;
    border: #008eab 2px solid;
}

.mod-form-company__text input[type="text"] {
    width: 100%;
    max-width: 450px;
    min-width: 450px;
    height: 38px;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
}

.col-facility-cot1 {
    width: 25%;
}

.col-facility-cot2 {
    width: 10%;
}

.col-facility-cot3 {
    width: 40%;
}

.col-facility-cot4 {
    width: 15%;
}

.col-facility-cot5 {
    width: 10%;
}

