.btn_mailsearch {
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    width: 100px;
    height: 30px;
    background-color: #5F5FF8;
    border-radius: 5px;
}

.mod-btn__test {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: #008eab;
    border: #008eab 2px solid;
}

.btn_mailsearchback.add {
    width: 90px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.btn_mailsearchbacktest {
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    background-color: grey;
}

.btn_mailsearchback:disabled {
    cursor: not-allowed;
    pointer-events: none; 
    border: 1px solid #999999; 
    background-color: #cccccc; 
    color: #666666;
}


.mod-btn_mailsearchback {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: #008eab;
    border: #008eab 2px solid;
}

.mod-form-mail__text input[type="text"] {
    width: 100%;
    min-width: 60%;
    height: 38px;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
}

.col-maintenance-cot1 {
    width: 13%;

}

.col-maintenance-cot2 {
    width: 10%;

}

.col-maintenance-cot3 {
    width: 40%;

}

.col-maintenance-cot4 {
    width: 15%;

}

.col-maintenance-cot5 {
    width: 12%;
}

.col-maintenance-cot6 {
    width: 10%;
}
/*ページネーション*/
.pager .pagination {
    text-align: left;
  }
  
  .pager .pagination li {
    display: inline;
    margin: 0 2px;
    padding: 0;
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    position: relative;
    border-radius: 50px;
    background-color: #fff;
    border: #008eab 2px solid;
  }
  
  .pager .pagination li div{
    background:none;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    text-align: center;
    display:table;
    color: #008eab;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all  0.3s ease;
  }
  
  .pager .pagination li div span{
    display:table-cell;
    vertical-align:middle;
  }
  
  .pager .pagination li div:hover,
  .pager .pagination li div.active{
    color: #fff;
    background-color: #f6ab00;
    border-radius: 50px;
    cursor: pointer;
  }
  
  .pager {
    display: flex;
    justify-content: center; 
    padding: 20px 0; 
}
.popup-maintenance {
    text-align: center;
    width: 50%;
    max-width: 80%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-40%, -45%);
    position: fixed;
}

.popup-content-maintenance {
    height: 270px !important;
    text-align: left;
    padding: 10px 55px 10px 55px;
    overflow-y: auto;
}

.popup-content-maintenance {
    height: auto;
    text-align: left;
    background: #FFFFFF;
    border-radius: 0 0 .4rem .4rem; 
}

/* .mod-form-mailTEST__text input[type="text"] {
    width: 100%;
    max-width: 80%;
    height: 38px;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
} */