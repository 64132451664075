.lay-master-set__form .user_input input[type="password"] {
    width: 80%;
    height: 40px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 15px;
    outline: 0;
    background-color: #fff;
    border: 1px solid #ddd;
}

.cp-btn__roundborder.add {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.cp-btn__roundborder.back {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
    background-color: #fff;
    color: #008eab;
}

.back-btn {
    margin-left: 10px;
}

.add-btn {
    margin-left: 80%;
}

.checkbox-input {
    margin-left: 0;
}

.checkbox-input:checked {
    color: #09ad7e;
}

.checkbox-parts {
    padding-left: 0;
    margin-right: 20px;
}

#setting2fa {
    margin-top: 60px;
}

.lay-master-set__form .mail-send__set .checkbox-input {
    margin-left: 6px;
}

.lay-master-set .mail-input {
    margin-bottom: 50px;
}

.lay-master-set .mail-btn {
    margin-left: 180px;
    width: 550px;
}

input[type="text"].password-mask {
    -webkit-text-security: disc; 
}

