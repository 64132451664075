.lay-master-set .mail-input .delete-btn {
    margin-left: 10px;
}

.mod-btn__delete {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.mod-btn__delete {
    text-align: center;
    display: block;
    color: #FF0000;
    border-radius: 23px;
    background-color: #fff;
    border: #FF0000 2px solid;
}

.mod-btn__back {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.mod-btn__back {
    text-align: center;
    display: block;
    color: grey;
    border-radius: 23px;
    background-color: #fff;
    border: grey 2px solid;
}

.mod-btn__company_option {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.mod-btn__company_option {
    text-align: center;
    display: block;
    color: #fff;
    border-radius: 23px;
    background-color: #008eab;
    border: #008eab 2px solid;
}