.device-id-content {
    background-color: #333; 
    color: #fff; 
    text-align: center; 
    padding: 10px 0; 
    border: 1px solid #ccc; 
    font-family: Arial, sans-serif; 
}

.table_scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 935px;
}

.device-id-content-head {
    text-align: center; 
    background-color: #4C4C4C; 
    color: white; 
    padding: 10px;
    border-bottom: none; 
    margin: 0;
}

.device-id-content-api {
    background-color: #979A9A; 
    color: white;
    padding: 10px; 
    text-align: left; 
    border-top: none; 
    margin: 0 0 20px;
}
.device-id-camera {
    color: white;
    padding: 10px; 
    text-align: left; 
    border: 1px solid #ccc; 
    border-top: none; 
    margin: 0 0 20px;
    height: 50px;
    
}

.device-id-content-option {
    text-align: center; 
    background-color: #4C4C4C; 
    color: white; 
    padding: 10px;
    border-bottom: none; 
    margin: 0; 
}

#other-settings-display {
    display: flex;
    justify-content: space-between;
    width: 100%; 
}

.setting-column {
    width: 50%; 
    padding: 10px;
    box-sizing: border-box; 
}

.camera-options {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    margin-left: 20px; 
}

.camera-option {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
}

.camera-option input[type="radio"] {
    position: absolute;
    opacity: 0; 
    cursor: pointer;
    z-index: 1; 
}

.camera-option span {
    position: relative;
    padding-left: 30px;
}

.camera-option span::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px; 
    height: 20px;
    border: 2px solid #555; 
    border-radius: 50%;
    background-color: white; 
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.camera-option input[type="radio"]:checked + span::before {
    border-color: #0078d7; 
    background-color: white; 
}

.camera-option input[type="radio"]:checked + span::after {
    content: '';
    position: absolute;
    left: 5px; 
    top: 50%;
    transform: translateY(-50%);
    width: 10px; 
    height: 10px;
    background-color: #0078d7; 
    border-radius: 50%;
}

.date-picker {
    width: 100%; 
    padding: 8px; 
    border: 1px solid #ccc; 
    font-size: 14px; 
    box-sizing: border-box; 
    background-color: white; 
}

.dropdown-container {
    width: 300px;
    margin-bottom: 10px;
}

.dropdown-title {
    display: block;
    font-size: 16px;
    color: white;
    background-color: #333;
    padding: 5px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.dropdown-select:focus {
    outline: none;
}

.input-container {
    width: 300px;
    margin-bottom: 10px;
    position: relative;
}

.input-title {
    display: block;
    font-size: 16px;
    color: white;
    background-color: #333;
    padding: 5px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.input-field {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    background-color: #fff;
}

.notification-container {
    text-align: center; 
    border-bottom: 1px solid gray; 
    padding-bottom: 10px; 
    box-sizing: border-box;
    padding: 10px 0 20px 0;
    margin: 0px 10px;
}
  
.notification-header {
    display: inline-block; 
    color: #000; 
}
  
.notification-message {
    display: inline-block; 
    color: white; 
    background-color: #e91e63; 
    text-align: center; 
    padding: 10px 20px; 
    margin: 10px auto; 
    width: 100%;
    box-sizing: border-box; 
}

.img_big {
    max-height: 200px;
    max-width: 220px;
    float: right;
}

.dt-style {
    margin-right: 10px; 
}

.line-with-text {
    position: relative;
    width: 100%;
    height: 40px; 
    background-color: #333; 
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center; 
    padding-left: 20px; 
}

.line-with-text span {
    position: absolute;
}

a, input[type="button"], button {
    -webkit-transition: ease .1s;
    transition: ease .1s;
}

.w100per {
    width: 100%;
}

.disp_in {
    display: inline-block;
}

.w70per {
    width: 70%;
}

.w30per {
    width: 30%;
}
.disp_in {
    display: inline-block;
}

.floatr {
    float: right;
}

.date-input {
    border: 1px solid #ccc;
    padding: 8px 10px;
    background-color: white;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.search_time {
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    width: 100%; 
}

.search_time_label {
    flex-shrink: 0; 
}
  
.search_time_input {
    flex-shrink: 0; 
}
  
.checkbox-input {
    margin-left: 7px; 
}

.checkbox-parts {
    background-color: #fff; 
}

.date-input {
    border: 1px solid #ccc;
    padding: 8px 10px;
    background-color: white;
    cursor: pointer;
    text-align: center;
}

.photo_search_container {
    display: flex;
    flex-direction: column;
    height: 310px; 
    background-color: transparent;
    color: #000;
    padding: 10px;
    border: 1px solid #000;
    margin: 10px 0px 10px 0;
    box-sizing: border-box;
    justify-content: space-between;
}

.photo_search_buttons {
    width: 100%;
}

.photo_search_buttons {
    display: flex;
    justify-content: flex-end; 

    gap: 10px;
}

.New_btn {
    background: #5F5FF8;
    color: #fff;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.date-picker-container {
    border-radius: 4px;      
    display: inline-block;   
    margin: 0 5px;           
}

.device-id-container {
    background-color: #4C4C4C; 
    color: #fff; 
    text-align: center; 
    padding: 10px 0; 
    margin: 20px 0; 
    border: 1px solid #ccc; 
    font-family: Arial, sans-serif; 
}

fieldset {
    border: 1px solid black;
    border-radius: 4px;
    position: relative; 
}

legend {
    font-size: 15px; 
    width: auto;
    margin-left: 10px;
    padding-left: 10px; 
    padding-right: 10px; 
}

fieldset:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px; 
}

.custom-file-upload {
    display: block;
    position: relative; 
    padding: 1px 10px; 
}

.input-button-container {
    display: flex;
    align-items: center; 
    padding: 8px; 
    margin-top: -40px;
  }

.mod-form-camera__text input[type="text"] {
    width: 100%;
    height: 38px;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
    margin: 0; 
    padding: 0; 
}

.lay-master__camera_option_form {
    width: 160px;
    height: 38px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 15px;
    outline: 0;
    background-color: #fff;
    background-image: url("../../../public/img/icon-select.png");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 14px 9px;
}

.input-field {
    flex-grow: 1; 
    margin-right: 10px; 
}
  
.popup-gauge {
    text-align: center;
    width: 100%;
    max-width: 1100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.popup-content-gauge {
    height: 80vh !important;
    text-align: left;
    padding: 10px 15px 10px 15px;
    overflow-y: auto;
    border-radius: 0 0 .9rem .9rem;
}

.popup-content-gauge {
    height: auto;
    /* max-height: 522px; */
    text-align: left;
    background: #FFFFFF;
}

.lay-master__table_form {
    width: 82px; 
    height: 30px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 15px;
    outline: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    background-image: url("../../../public/img/icon-select.png"); 
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 14px 9px;
}

.lay-master__option_form {
    width: 200px; 
    height: 30px;
    font-size: 16px;
    line-height: 1.7;
    padding-left: 15px;
    outline: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    background-image: url("../../../public/img/icon-select.png"); 
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 14px 9px;
    margin-left: 10px;
    margin-bottom: 10px; 
}
  
.photo_manual_row {
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    padding-left: 10px;
}
.select#up-method {
    width: 200px;
}

.diff_limit {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px 20px;
    align-items: start;
}

.upper-group,
.lower-group {
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    gap: 10px; 
}

.upperlimit,
.lowerlimit {
    display: flex;
    align-items: center;
    gap: 5px; 
}

.mod-form-camera__text {
    display: flex;
    align-items: center;
    gap: 5px;
}

.mod-form-camera__text input[type="text"] {
    width: 160px; 
    font-size: 14px;
    padding: 5px;
    box-sizing: border-box;
}

.error-msg {
    color: red;
    font-size: 12px;
    margin-left: 10px; 
}

/* .mod-form-camera__text input[type="text"] {
    height: 30px;
    width: 150px;
    font-size: 14px;
} */

.mod-form-camera__text {
    margin-right: 5px; 
}

.checkbox-input {
    margin: 0; 
}

.label-style {
    margin-right: 5px; 
}

.container {
    padding: 0; 
}

.label-style {
    background-color: #4C4C4C;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 90px;
    grid-row: span 2; 
}

.w150 {
    width: 150px;
    border: 1px solid #ccc;
    padding: 8px;
}

.upperlimit, .lowerlimit {
    display: flex; 
    align-items: center; 
    gap: 5px; 
    margin: 0; 
}

span {
    margin: 0; 
    padding: 0;
    /* white-space: nowrap; */
}

.mod-form-camera__set dl dt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 38px;
    background-color: #777777;
    font-size: 15px;
    font-weight: normal !important;
    color: #fff;
}

.custom-datepicker {
    width: 100%;
    height: 38px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
}

.custom-datepickerchange {
    width: 100%;
    height: 38px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
    border: 1px solid #ccc;
}

.custom-datepicker {
    width: 100%; 
    max-width: none;
}

.react-datepicker {
    border: 1px solid #a6c9e2;
    border-radius: 5px;
    background-color: #f0f7fc;
}
  
.react-datepicker__header {
    background-color: #e1f0f9;
    border-bottom: 1px solid #a6c9e2;
}
  
.react-datepicker__day--today {
    background-color: #fbec88 !important;
    border-radius: 50%;
    color: #000;
}
  
.react-datepicker__day:hover {
    background-color: #cdeff7;
    color: #000;
}

.highlight-sunday {
    color: #ff0000 !important;     
    font-weight: bold; 
}

.highlight-saturday {
    color: #0000ff !important;
    font-weight: bold; 
}

.react-datepicker__day:nth-child(7n) { 
    color: #f00;
}

.react-datepicker__navigation {
    top: 10px;
    outline: none;
    border: none;
    background: none; 
}

.react-datepicker__day:hover {
    background-color: #d9d9d9;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
    width: 2em; 
    line-height: 2em; 
    margin: 0.166em;
    border-radius: 0.3em; 
}

.react-datepicker__current-month, .react-datepicker-time__header {
    font-size: 16px; 
    height: 50px; 
    display: flex;
    align-items: center; 
    justify-content: center; 
    color: #ffffff; 
    background: #5c9ccc;
    font-family: Lucida Grande, Lucida Sans, Arial, sans-serif;
}

.react-datepicker__navigation {
    top: 17px;
}

.react-datepicker__current-month {
    position: relative;
}

.react-datepicker__current-month:after {
    content: '年';
}

.react-datepicker__header--custom:after {
    content: '月';
    position: absolute;
    right: 10px;
    top: 15px;
}

.react-datepicker__navigation {
    visibility: hidden; 
}

.react-datepicker__navigation--next, .react-datepicker__navigation--previous {
    visibility: visible; 
}

.react-datepicker__day, 
.react-datepicker__month-text, 
.react-datepicker__quarter-text, 
.react-datepicker__year-text {
    border: 1px solid #c5dbec;
    background: #dfeffc;
    text-align: center;
    font-weight: bold;
    color: #2e6e9e;
    font-size: 20px;
}

.react-datepicker__day:hover,
.react-datepicker__day--today,
.react-datepicker__day--selected {
    border-radius: 8px; 
}

.react-datepicker__day--selected {
    border: 1px solid #79b7e7;
    background: #f5f8f9 ;
    font-weight: bold;
    color: #e17009 !important;
}

.react-datepicker__header {
    border-radius: 8px 8px 0 0; 
}

.react-datepicker__day--outside-month {
    visibility: hidden; 
}

.date-picker-container {
    position: relative; 
    z-index: 1000; 
    overflow: visible; 
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 3.0rem !important;
}

.input-field, .date-picker, .dropdown-select, .input-container {
    width: 100%;
}

.setting-column, .photo_search_container {
    flex-direction: column;
}

.camera-option {
    flex: 1; 
    text-align: center;
}

.camera-option {
    max-width: 150px;
    flex: 1;
    text-align: center;
}

.img_small {
    width: 100px;
    height: 100px; 
    object-fit: cover; 
    display: block; 
}

.text-block {
    display: flex;
    flex-direction: column; 
    gap: 8px;
    flex-grow: 1; 
    justify-content: center; 
}

.overlay {
    background-color: #c0c0c04d;
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 250px);
    height: 100%;
    z-index: 998;
    display: flex; 
    align-items: center;
    justify-content: center;
    opacity: 0; 
    transition: opacity 0.3s ease-in-out;
}

.overlaycompany {
    background-color: #c0c0c04d;
    position: fixed;
    top: 0;
    right: 0;
    width: 100% ;
    height: 100%;
    z-index: 998;
    display: flex; 
    align-items: center;
    justify-content: center;
    opacity: 0; 
    transition: opacity 0.3s ease-in-out;
}

.overlay.active {
    opacity: 1;
}

.overlaycompany.active {
    opacity: 1;
}

.spinner {
    display: none; 
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.spinner.active {
    display: block;
}

.button-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
    width: 100%; 
    margin-top: 10px; 
}
  
.button {
    margin-top: 3%; 
}

.notification-item {
    display: flex;
    align-items: center; 
    justify-content: flex-start; 
    gap: 20px; 
    padding: 15px;
    border-bottom: 1px solid #ccc; 
    box-sizing: border-box; 
    min-height: 120px;
}

.gauge-value {
    display: inline-block;
    width: 70%; 
}

.gauge-label {
    display: flex;
    justify-content: flex-start;
    width: 100%; 
    margin-bottom: 10px; 
}

.gauge-name {
    white-space: normal;
}

.gauge-label span {
    word-break: break-word;
    max-width: 100%; 
}

.gauge-label span:first-child {
    flex-basis: 27%; 
    text-align: right; 
}
  
.gauge-label span:last-child {
    max-width: 60%;
    padding-left: 10px; 
    text-align: left; 
}

.mod-btn__camerachange {
    text-align: center;
    display: block;
    width: 90px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
    color: red;
    background-color: #979A9A;
    border: #979A9A 2px solid;
}

.mod-btn__cameraelete {
    text-align: center;
    display: block;
    width: 90px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
    color: #fff;
    background-color: #3CD602;
    border: #3CD602 2px solid;
}

.mod-btn__cameraelete:disabled {
    background-color: #cccccc;
    color: #666666;
    border: #cccccc 2px solid;
}

.mod-btn__roundbordersearch {
    text-align: center;
    display: block;
    color: #fff;
    background-color: #5F5FF8;
    width: 110px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 5px;
}

.up-manual-msg {
    text-align: center;
    font-size: 16px;
    color: limegreen;
    height: 32px;
    margin-bottom: 5px;
}

.div-border-bottom {
    margin-top: 10px;
    border-bottom: 1px solid gray;
}

.img_180_rotate {
	transform: rotate(180deg);
}

.hidden-content {
    visibility: hidden;
    height: 48px; 
}

.disabled-overlay button, 
.disabled-overlay .popup-close  {
    background-color: #777780;
    border-color: #777780;    
    color: #fff;          
    pointer-events: none; 
    cursor: not-allowed;    
}

.disabled-overlay input,
.disabled-overlay textarea,
.disabled-overlay select {
    background-color: lightgrey;
    border-color: #ccc;    
    color: #fff;         
    pointer-events: none;    
    cursor: not-allowed;    
}

.disabled-overlay input[type="text"] {
    background-color: lightgrey; 
    border: 1px solid #ccc; 
    color: #fff;    
    cursor: not-allowed;   
}

#photo-search-div {
    overflow-y: auto; 
    flex: 1; 
}

.photo_search_buttons {
    flex-shrink: 0; 
    margin-top: 10px; 
}

.upload-wait-container {
    display: flex; 
    align-items: center; 
    gap: 3px; 
    justify-content: center; 
}

.progress-bar {
    display: flex; 
    align-items: center; 
    justify-content: center;
    color: white; 
    font-weight: bold; 
    font-size: 14px; 
    height: 100%; 
    transition: width 0.3s ease; 
}

.progress-bar {
    min-width: 50px; 
}

.mod-btn__roundbordercamera {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 30px;
    width: 90px;
    height: 35px;
    border-radius: 5px;
    background-color: #5F5FF8;
}

.mod-btn__camerachangedelete {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 30px;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    background-color: #FF0000;
    border: #fff;
}