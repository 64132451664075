.lay-master-set-wrap {
    padding: 10px 45px 45px 45px; 
    background-color: #dcf1fa;
    border-radius: 35px;
    /* overflow: auto;  */
}

table {
    width: 100%; 
    table-layout: fixed; 
}

th, td {
    white-space: normal; 
    overflow: visible;
    word-wrap: break-word; 
    min-height: 50px;
    vertical-align: top; 
}

.col-api-token {
    max-width: 300px; 
}

.lay-master-set__title {
    text-align: center;
    margin-bottom: 10px;
}

.lay-master-set__title span {
    display: inline-block;
    font-size: 29px;
    font-weight: 500;
    padding-left: 48px;
    background-size: 39px auto;
    color: #008eab;
}

.lay-master-set__frame {
    width: 100%;
}

.lay-master__table {

    margin: 0px;
    width: 100%;
}


#set_company_list .lay-master-set .get_api_management button {
    text-align: center;
    display: block;
    width: 180px;
    color: #fff;
    font-size: 16px;
    height: 35px;
    background-color: #008eab;
    border-radius: 10px;
}

.lay-master__table table {
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
}

.lay-master__table table tr th {
    height: 60px;
    text-align: center;
    color: #fff;
    background-color: #777777;
    vertical-align: middle;
}

.lay-master__table table tr td.lay-td:nth-of-type(1) {
    width: 10%;
    padding-left: 35px;
}

.lay-master__table table {
    border-collapse: collapse; 
    width: 100%;
    border: 1px solid #ccc;}

.lay-master__table th,
.lay-master__table td {
    border: 1px solid #ccc; padding: 8px;
    text-align: left; 
}

.lay-master__table table tr td.lay-td:nth-of-type(2) {
    width: 70%;
    text-align: center;
}

.lay-master__table span {
    word-break: break-all;
}

.lay-master-set .trun-btn {
    text-align: center;
}

.master_long_btn {
    text-align: center;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 300px;
    height: 45px;
    background-color: #008eab;
    border-radius: 23px;
}

#companyPopup .popup-content {
    height: 250px !important;
}

.mod-master-form__set dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.mod-master-form__set dl dt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 160px;
    height: 50px;
    background-color: #777777;
    font-size: 16px;
    color: #fff;
}

.mod-master-form__set dd input[type="text"] {
    width: 500px;
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 15px;
    outline: 0;
    background-color: #fff;
}

.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
}

.col-admin-cot1 {
    width: 28%;

}

.col-admin-cot2 {
    width: 28%;

}

.col-admin-cot3 {
    width: 17%;

}

.col-admin-cot4 {
    width: 17%;

}

.col-admin-cot5 {
    width: 10%;

}

.mod-btn__roundborderNew {
    text-align: center;
    display: block;
    width: 180px;
    color: #fff;
    font-size: 16px;
    height: 35px;
    background-color: #008eab;
    border-radius: 10px;
    margin-bottom: 10px;
}

.mod-btn__adminlist {
    text-align: center;
    display: block;
    color: #008eab;
    font-size: 12px;
    line-height: 27px;
    width: 100%;
    height: 30px;
    border-radius: 23px;
    background-color: #fff;
    border: #008eab 2px solid;
}

.mod-btn__roundborder:hover {
    color: #f6ab00;
    border: #f6ab00 2px solid;
}

.mod-btn__roundborder.small {
    height: 38px;
    font-size: 15px;
    line-height: 34px;
    border-radius: 19px;
}

.mod-btn__roundborder.moresmall {
    height: 30px;
    font-size: 12px;
    line-height: 29px;
    border-radius: 15px;
    cursor: pointer;
}

button {
    outline: none !important; 
}

@media (max-width: 767px) {
    .display-main {
        padding-top: 100px;

    }
}

@media (max-width: 990px) and (max-height: 560px) and (orientation: landscape) {
    .display-main {
        padding-left: 80px;
        padding-top: 100px;
    }
}