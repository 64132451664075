.page-not-found-box {
    width: 90%; 
    margin: 10px auto; 
    border: 1px solid #D0D0D0;
    box-shadow: 0 0 8px #D0D0D0;
    text-align: left; 
    padding: 20px;
}

.page-not-found-title {
    border-bottom: 1px solid #D0D0D0;
    font-size: 19px;
    font-weight: normal;
    margin: 0 0 14px 0;
    padding: 14px 15px 10px 15px;
}
  
.page-not-found-text {
    margin: 12px 15px;
    font-size: 14px;
    color: #666;
}
  
.page-not-found-link {
    color: #007BFF;
    font-size: 1rem;
    border: 1px solid #007BFF;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    display: inline-block;
}
