.col-deleted-cot1 {
    width: 18%;

}
.col-deleted-cot2 {
    width: 20%;

}
.col-deleted-cot3 {
    width: 20%;

}
.col-deleted-cot4 {
    width: 20%;

}
.col-deleted-cot5 {
    width: 12%;

}
.col-deleted-cot6 {
    width: 10%;

}
