.mod-form__text select {
    width: 100%;
    height: 38px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
    background-image: url("../../../public/img/icon-select.png");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 20px 14px;
}

.mod-form__set dl dt.double {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 70px;
    background-color: #777777;
    font-size: 15px;
    font-weight: normal !important;
    color: #fff;
}