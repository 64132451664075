
.col-company-cot1 {
    width: 15%;
}

.col-company-cot2 {
    width: 20%;
}

.col-company-cot3 {
    width: 25%;
}

.col-company-cot4 {
    width: 20%;
}

.col-company-cot5 {
    width: 15%;
}

.col-company-cot6 {
    width: 10%;

}

#CompanyList table tr.disabled td, #FacilityList table tr.disabled td, #CameraList table tr.disabled td, #UserList table tr.disabled td {
    background-color: #f0f0f0 !important;
}

#CompanyList table tr td, #FacilityList table tr td , #CameraList table tr td , #UserList table tr td{
    background-color: #ffffff !important;
}

.lay-master__table table:not(#CompanyList):not(#FacilityList):not(#CameraList) :not(#UserList) tr:nth-of-type(2n-1) td {
    background-color: #f0f0f0;
}

.mod-btn__companyedit {
    text-align: center;
    display: block;
    color: #fff;
    border-radius: 23px;
    background-color: #008eab;
    border: #008eab 2px solid;
}

.mod-btn__companyedit.add {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.mod-btn__companylist {
    display: inline-block;
    margin: auto;
    color: #008eab;
    border-radius: 23px;
    background-color: #fff;
    border: #008eab 2px solid;
    width: 100%;
    max-width: 60px;
    height: 30px;
    font-size: 12px;
}

.col-companyedit {
    text-align: center;
    padding: 0 20px; 
}

.mod-form__company input[type="text"] {
    width: 100%;
    height: 38px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
}

.lay-master-set .mail-send__set {
    margin-left: 2%;
}

.lay-master-set .mail-input {
    margin-bottom: 50px;
}

.lay-master-set .mail-input span {
    color: #666;
}

.lay-master-set .mail-input dd{
    max-width: 70% !important;
}

.lay-master-set .mail-input input[type="text"] {
    max-width: none;
}

.lay-master-set .mail-input .back-btn {
    margin-left: 10px;
}

.lay-master-set .mail-input .add-btn {
    margin-left: 55%; 
}

@media (max-width: 1047px) {
    .lay-master-set .mail-input .add-btn {
        margin-left: 25%; 
    }
}

@media (max-width: 951px) {
    .lay-master-set .mail-input .add-btn {
        margin-left: 5%; 
        flex-wrap: wrap; 
    }
}

.mod-btn__roundborder.add {
    width: 90px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.mod-btn__roundborder.back {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
    background-color: #fff;
    color: #008eab;
}

.lay-master-set .mail-btn {
    margin-left: 180px;
    width: 550px;
}

.mod-btn__roundborder.editmoresmall {
    width: 100px;
    height: 38px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.mod-btn__roundborder:disabled {
	cursor: not-allowed;
	pointer-events: none;
	border: 1px solid #999999;
	background-color: #cccccc;
	color: #666666;
}


.mod-form__set dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 0 !important;
}

.mod-form__set dl dt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 200px;
    height: 38px;
    background-color: #777777;
    font-size: 15px;
    font-weight: normal !important;
    color: #fff;
}
.mod-form__set dl dt.textarea {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 200px;
    height: 76px;
    background-color: #777777;
    font-size: 15px;
    font-weight: normal !important;
    color: #fff;
}

dt {
    display: block;
    width: auto;
}

.mod-form__set dl dd {
    width: 315px;
    margin-bottom: 0 !important;
}


a, input[type="button"], button {
    -webkit-transition: ease .1s;
    transition: ease .1s;
}

input[type='submit'], input[type='button'], label, button, select {
    cursor: pointer;
}


img {
    background-color: transparent;
    vertical-align: top;
}



.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 25px;
}



.checkbox-parts {
    padding-left: 2px;
    position: relative;
    top: -5px;
    margin-right: 20px;
}


.mod-form__textarea textarea {
    width: 100%;
    height: 200px;
    font-size: 18px;
    line-height: 1.6;
    padding: 20px;
    background-color: #fff;
    resize: none;
    border: #d9d9d9 1px solid;
}

textarea {
    resize: vertical;
}

table {
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 100%;
}

th {
    font-weight: normal;
    text-align: left;
}


.mod-btn__round.small {
    height: 38px;
    font-size: 15px;
    line-height: 34px;
    border-radius: 19px;
}

.mod-btn__round.moresmall {
    height: 30px;
    font-size: 12px;
    line-height: 29px;
    border-radius: 15px;
}

.mod-btn__round {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    background-color: #008eab;
    border-radius: 23px;
}


.mod-btn__roundborder {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: #008eab;
    border: #008eab 2px solid;
}

.mod-btn__roundborder:hover {
    color: #f6ab00;
    border: #f6ab00 2px solid;
}

a, input[type="button"], button {
    -webkit-transition: ease .1s;
    transition: ease .1s;
}

.mod-btn__round:hover {
    background-color: #f6ab00;
}

.mod-btn__roundborder.morelong {
    text-align: center;
    display: block;
    color: #fff;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
    width: 180px;
}

.mod-no-data {
    margin: 0px;
    background: #e83e8c;
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: 18px;
}

.mod-form__text textarea {
    width: 100%;
    height: 76px;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 11px;
    background-color: #fff;
    resize: none;

}