.col-camera-cot1 {
    width: 20%;

}

.col-camera-cot2 {
    width: 20%;

}

.col-camera-cot3 {
    width: 20%;

}

.col-camera-cot4 {
    width: 20%;

}

.col-camera-cot5 {
    width: 10%;

}

.col-camera-cot6 {
    width: 10%;

}