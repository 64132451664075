.lay-master-set .mail-send__set {
    margin-left: 2%;
}


.lay-master-set .mail-input {
    margin-bottom: 50px;
}

.lay-master-set .mail-input dd{
    max-width: 70% !important;
}

.lay-master-set .mail-input .back-btn {
    margin-left: 10px;
}
.lay-master-set .mail-input .add-btn {
    margin-left: 55%;
}

.mod-btn__roundborder.back {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
    background-color: #fff;
    color: #008eab;
}

.lay-master-set .mail-btn {
    margin-left: 180px;
    width: 550px;
}

.mod-btn__roundborder.editmoresmall {
    width: 100px;
    height: 38px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.mod-btn__roundborder:disabled {
	cursor: not-allowed;
	pointer-events: none;
	border: 1px solid #999999;
	background-color: #cccccc;
	color: #666666;
}



.checkbox-parts::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 4px;
    background: #fff;
}

.checkbox-input:checked + .checkbox-parts::after {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 5px;
    width: 6px;
    height: 13px;
    transform: rotate(20deg);
    border-bottom: 2px solid #09ad7e;
    border-right: 2px solid #09ad7e;
}

.mod-btn__adminedit:disabled {
    cursor: not-allowed;
    pointer-events: none;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}