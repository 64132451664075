

  
.lay-master-set .mail-input .back-btn {
    margin-left: 10px;
}

.mod-btn__roundborder.back {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
    background-color: #fff;
    color: #008eab;
}
  
.lay-search__form {
    padding-bottom: 6px;
    border-bottom: #fff 1px solid;
    margin-bottom: 36px;
}

.lay-list__table__comment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 333px;
    width: 280px;
}
  
.lay-list__table table tr td {
    height: 50px;
    vertical-align: middle;
    text-align: center;
    word-break: break-word;
}
  
  
  .lay-list__table table tr:nth-of-type(2n) td {
    background-color: #f0f0f0;
  }
  
  .mod-btn__round.small {
    height: 38px;
    font-size: 15px;
    line-height: 34px;
    border-radius: 19px;
  }
  
  .mod-btn__round.moresmall {
    height: 30px;
    font-size: 12px;
    line-height: 29px;
    border-radius: 15px;
  }
  
  .mod-btn__round {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    background-color: #008eab;
    border-radius: 23px;
  }
  
  
  a, input[type="button"], button {
    -webkit-transition: ease .1s;
    transition: ease .1s;
  }
  
  .mod-btn__round:hover {
    background-color: #f6ab00;
  }
  
  
  .spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    display: none; 
  }
  
  .spinner.active {
    display: block; 
    width: 24px;
    height: 24px;
  }
  
  .spinner-count {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid transparent;
    border-top-color: #212529;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    vertical-align: middle;
  }

.mod-form__text input[type="text"] {
    width: 100%;
    height: 38px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
}
  
  /* @media (max-width: 576px) {
    .container-datamanage {
        width: 100%;
        padding: 15px;
    }
  
    .lay-search-wrap {
        padding: 20px;
    }
  
    .lay-search__form__col1,
    .lay-search__form__col2 {
        margin-bottom: 0;
    }

  
    .lay-search__form__col1 ul li{
        width: 100%;
        margin-bottom: 10px;
    }
  
    .lay-search__form__col2 ul li {
        width: 100%;
        margin-bottom: 10px;
    }
  
    .lay-search__form__col1 ul li:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  
    .lay-search__btn,
    .lay-search_terms__btn {
        width: 100%;
    }
  
    .custom-datepicker {
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }
  
    .lay-search__form__col1 ul {
        display: flex;
        flex-direction: column;
    }
  
    .lay-search__form__col1 li {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
  
    .mod-form__set {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        
    }
  
    .mod-form__set-between {
        height: auto;
    }
  
    .custom-datepicker {
        width: 100%; 
    }
  
    .lay-search_terms__list {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
  
    .lay-search_terms__list img {
        height: 50px;
        padding-right: 10px;
    }
    
    .lay-search_terms__btn, .pager-frame .flex-container{
        display: none;
    }
  
    .lay-list__horizontal li{
        width: 100%;
    }
  
    #dispDataMnageData {
        margin: 0 auto;
        padding: 10px;
    }
  
    .lay-tab {
        width: 100%;
        margin-bottom: 10px;
    }
  
    .lay-tab__content {
        clear: both;
    }
  
    .lay-tab__content .pager-frame {
        margin-bottom: 20px;
        width: 100%;
    }
  
    .flex-container {
        display: flex;
        justify-content: center;
        align-items: baseline;
        margin-top: 15px;
        flex-direction: column;
    }
  
    .lay-tab__content .result_num {
        margin-bottom: 10px;
        text-align: center;
    }
  
    .lay-tab__head {
        width: 100%;
        margin-bottom: 10px;
    }
  
    .lay-tab__head ul {
        display: flex;
        justify-content: center;
    }
  
    .lay-tab__head ul li {
        width: 45%;
        font-size: 14px;
        line-height: 30px;
    }
  
    .lay-tab__head ul li.current {
        background-color: #008eab;
        color: #fff;
    }
  
    .lay-list__horizontal {
        margin-bottom: 10px;
        width: 100%;
    }
  
    .lay-list__horizontal ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
  
    .lay-list__horizontal__date {
        font-size: 14px;
        margin-bottom: 5px;
        text-align: center;
        word-break: break-all;
    }
  
    .lay-list__horizontal__name {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
        text-align: center;
        word-break: break-all;
    }
  
    .lay-list__horizontal__checkbox {
        margin-bottom: 5px;
        text-align: center;
    }
  
    .lay-list__horizontal__pct img {
        height: auto;
        max-width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
  
    .lay-list__horizontal__comment {
        padding: 10px 5px;
        margin-top: 10px;
        text-align: center;
    }
  
    .mod-form__textarea textarea {
        width: 100%;
        height: 150px;
        font-size: 14px;
        line-height: 1.5;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
  
    .lay-search__form .second-calendar {
        text-align: right;
    }     
  
    .lay-list__horizontal__btn {
        padding: 10px;
        text-align: center;
    }
  
    .mod-btn__round.small {
        height: 34px;
        font-size: 14px;
        line-height: 32px;
        border-radius: 15px;
    }
  
    .lay-list__horizontal ul li {
        width: 100%;
        padding: 10px 0;
        border-top: #008eab 2px solid;
        margin-right: 0;
    
    }
  
    .mod-form__set-between {
        width: 100%;
        margin: 0 10px 0 10px;
    }
  
    .mod-form__text input[type="text"] {
        min-width: 225px;
        max-width: 315px;
    }
  
    #searchNewData {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
  } */
  
  /* @media (min-width: 577px) and (max-width: 767px) {
    .container-datamanage {
        width: 100%;
        padding: 15px;
    }
  
    .lay-search-wrap {
        padding: 20px;
    }
  
    .lay-search__form__col1,
    .lay-search__form__col2 {
        margin-bottom: 0;
    }

  
    .lay-search__form__col1 ul li{
        width: 100%;
        margin-bottom: 10px;
    }
  
    .lay-search__form__col2 ul li {
        width: 100%;
        margin-bottom: 10px;
    }
  
    .lay-search__form__col1 ul li:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  
    .lay-search__btn,
    .lay-search_terms__btn {
        width: 100%;
    }
  
    .custom-datepicker {
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }
  
    .lay-search__form__col1 ul {
        display: flex;
        flex-direction: column;
    }
  
    .lay-search__form__col1 li {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
  
    .mod-form__set {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }
  
    .mod-form__set-between {
        height: auto;
    }
  
    .custom-datepicker {
        width: 100%; 
    }
  
    .lay-search_terms__list {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
  
    .lay-search_terms__list img {
        height: 50px;
        padding-right: 10px;
    }
    
    .lay-search_terms__btn, .pager-frame .flex-container{
        display: none;
    }
  
    .lay-list__horizontal li{
        width: 100%;
    }
  
    #dispDataMnageData {
        margin: 0 auto;
        padding: 10px;
    }
  
    .lay-tab {
        width: 100%;
        margin-bottom: 10px;
    }
  
    .lay-tab__content {
        clear: both;
    }
  
    .lay-tab__content .pager-frame {
        margin-bottom: 20px;
        width: 100%;
    }
  
    .flex-container {
        display: flex;
        justify-content: center;
        align-items: baseline;
        margin-top: 15px;
        flex-direction: column;
    }
  
    .lay-tab__content .result_num {
        margin-bottom: 10px;
        text-align: center;
    }
  
    .lay-tab__head {
        width: 100%;
        margin-bottom: 10px;
    }
  
    .lay-tab__head ul {
        display: flex;
        justify-content: center;
    }
  
    .lay-tab__head ul li {
        width: 45%;
        font-size: 14px;
        line-height: 30px;
    }
  
    .lay-tab__head ul li.current {
        background-color: #008eab;
        color: #fff;
    }
  
    .lay-list__horizontal {
        margin-bottom: 10px;
        width: 100%;
    }
  
    .lay-list__horizontal ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
  
    .lay-list__horizontal__date {
        font-size: 14px;
        margin-bottom: 5px;
        text-align: center;
        word-break: break-all;
    }
  
    .lay-list__horizontal__name {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
        text-align: center;
        word-break: break-all;
    }
  
    .lay-list__horizontal__checkbox {
        margin-bottom: 5px;
        text-align: center;
    }
  
    .lay-list__horizontal__pct img {
        height: auto;
        max-width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
  
    .lay-list__horizontal__comment {
        padding: 10px 5px;
        margin-top: 10px;
        text-align: center;
    }
  
    .mod-form__textarea textarea {
        width: 100%;
        height: 150px;
        font-size: 14px;
        line-height: 1.5;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
  
    .lay-list__horizontal__btn {
        padding: 10px;
        text-align: center;
    }
  
    .mod-btn__round.small {
        height: 34px;
        font-size: 14px;
        line-height: 32px;
        border-radius: 15px;
    }
  
    .lay-list__horizontal ul li {
        width: 100%;
        padding: 10px 0;
        border-top: #008eab 2px solid;
        margin-right: 0;
    
    }
  
    .mod-form__set-between {
        width: 100%;
        margin: 0 10px 0 10px;
    }
  
    .mod-form__text input[type="text"] {
        min-width: 225px;
        max-width: 315px;
    }
  
    #searchNewData {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
  } */
  
  /* @media (min-width: 768px) and (max-width: 991px) {
    .container-datamanage {
        width: 100%; 
        padding: 10px;
        margin: 0 auto;
    }
  
    .lay-search-wrap {
        padding: 20px; 
        background-color: #dcf1fa;
        border-radius: 20px; 
    }
  
    .lay-search__title span {
        font-size: 24px;
        background-size: 30px auto; 
        padding-left: 36px; 
    }
  
    .lay-search__lead {
        font-size: 14px; 
    }
  
    .lay-search__form {
        margin-bottom: 24px; 
    }
  
    .lay-search__form__col1 ul li,
    .lay-search__form__col2 ul li {
        width: 100%; 
        margin-bottom: 10px;
        margin-right: 5px;
    }
  
    .lay-search__form__col2 ul {
        display: flex;
        flex-wrap: nowrap;
    }
  
    .mod-form__set dl dt {
        width: 100px; 
        height: 32px; 
        font-size: 14px;
    }
  
    .mod-form__set dl dd {
        width: 100%;
        margin-bottom: 10px;
    }
  
    .mod-form__text.calendar input[type="text"],
    .mod-form__select select {
        width: 100%; 
        max-width: none; 
        height: 32px;
        font-size: 14px; 
    }
  
    .mod-form__set-between {
        font-size: 10px; 
        width: 40px; 
        height: 32px;
    }
  
    .mod-btn__round {
        font-size: 16px; 
        height: 40px;
        line-height: 38px;
        border-radius: 20px; 
        word-break: keep-all;
        padding: 0 5px;
    }
  

    .lay-list__horizontal__pct img {
        height: 150px; 
    }
  
    .lay-tab__head {
        width: 70%; 
        margin-bottom: 10px;
    }
  
    .lay-tab__head ul li {
        font-size: 14px; 
        width: 50%; 
        height: 34px;
    }
  
    .pager .pagination li {
        font-size: 12px;
    }
  
    .custom-datepicker {
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }
  
    .lay-search__form__col2 {
        margin-bottom: 0;
    }
  
    .lay-search__form__col1 {
        display: flex; 
        align-items: center; 
    }
  
    .lay-search__form__col1 ul {
        display: flex; 
        list-style: none; 
        padding: 0;
        margin: 0;
    }
  
    .lay-search__form__col1 ul li:last-child {
        margin-right: 0;
    }
  
    .mod-form__set dl {
        display: flex; 
        align-items: center; 
        margin: 0; 
    }
  
    .mod-form__set dl dd {
        margin: 0;
    }
  
    .mod-form__set-between {
        margin: 0 10px; 
    }
  
    .mod-form__text.calendar {
        flex: 1; 
        max-width: none; 
    }
  
    .custom-datepicker {
        width: 100%; 
        max-width: none;
    }
  
    .lay-search_terms__list__text {
        width: auto;
        padding-right: 15px;
        padding-left: 15px;
    }
  
    .lay-search_terms-wrap, .lay-search_terms__list {
        display: flex;
        flex-wrap: nowrap;
    }
  } */
  
  /* @media (min-width: 992px) and (max-width: 1199px) {
    .container-datamanage {
        width: 100%; 
        padding: 10px; 
        margin: 0 auto; 
    }
  
    .lay-search-wrap {
        padding: 20px; 
        background-color: #dcf1fa;
        border-radius: 20px; 
    }
  
    .lay-search__title span {
        font-size: 24px; 
        background-size: 30px auto;
        padding-left: 36px;
    }
  
    .lay-search__lead {
        font-size: 14px; 
    }
  
    .lay-search__form {
        margin-bottom: 24px; 
    }
  
    .lay-search__form__col1 ul li,
    .lay-search__form__col2 ul li {
        width: 100%; 
        margin-bottom: 10px; 
        margin-right: 5px;
    }
  
    .lay-search__form__col2 ul {
        display: flex;
        flex-wrap: nowrap;
    }
  
    .mod-form__set dl dt {
        width: 100px; 
        height: 32px;
        font-size: 14px; 
    }
  
    .mod-form__set dl dd {
        width: 100%; 
        margin-bottom: 10px;
    }
  
    .mod-form__text.calendar input[type="text"],
    .mod-form__select select {
        width: 100%; 
        max-width: none; 
        height: 32px; 
        font-size: 14px; 
    }
  
    .mod-form__set-between {
        font-size: 10px; 
        width: 40px; 
        height: 32px; 
    }
  
    .mod-btn__round {
        font-size: 16px;
        height: 40px; 
        line-height: 38px; 
        border-radius: 20px; 
    }
  

    .lay-list__horizontal__pct img {
        height: 150px;
    }
  
    .lay-tab__head ul li {
        font-size: 14px; 
        width: 50%; 
        height: 34px; 
    }
  
    .pager .pagination li {
        font-size: 12px;
    }
  
    .custom-datepicker {
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }
  
    .lay-search__form__col2 {
        margin-bottom: 0;
    }
  
    .lay-search__form__col1 {
        display: flex;
        align-items: center;
    }
  
    .lay-search__form__col1 ul {
        display: flex;
        list-style: none; 
        padding: 0;
        margin: 0;
    }
  
    .lay-search__form__col1 ul li:last-child {
        margin-right: 0;
    }
  
    .mod-form__set dl {
        display: flex; 
        align-items: center; 
        margin: 0; 
    }
  
    .mod-form__set dl dd {
        margin: 0;
    }
  
    .mod-form__set-between {
        margin: 0 10px;
    }
  
    .mod-form__text.calendar {
        flex: 1; 
        max-width: none; 
    }
  
    .custom-datepicker {
        width: 100%; 
        max-width: none; 
    }
  
    .lay-search_terms__list__text {
        width: auto;
        padding-right: 15px;
        padding-left: 15px;
    }
  
    .lay-search_terms-wrap, .lay-search_terms__list {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
  } */
  
.react-datepicker-wrapper {
    width: 100%;
}
  .popup-wrapper {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    overflow: auto;
    z-index: 10;
    cursor: pointer;
  }
  
  
  .popup-content {
    height: 630px !important;
    text-align: left;
    padding: 10px 55px 10px 55px;
    overflow-y: auto;
    border-radius: 0 0 .4rem .4rem;
  }
  
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin: -50px;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: #888;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 3px 0 0 3px;
    user-select: none;
  }
  
  .next {
    right: 75px;
    border-radius: 0 3px 3px 0;
  }
  
  .date_popup {
    display: flex;
    max-width: 400px;
    margin-bottom: 10px;
  }
  
  .lay-list__popup__checkbox {
    padding-top: 45px;
    padding-left: 25px;
  }
  
  .lay-list__popup__checkbox .checkbox-parts {
    padding-left: 0;
    margin-right: 25px;
  }
  
  .lay-list__popup__checkbox .checkbox-parts::before {
    position: absolute;
    top: 3px;
    left: 0;
  }
  
  .lay-list__popup__checkbox .checkbox-input:checked + .checkbox-parts::after {
    position: absolute;
    top: 5px;
    left: 5px;
  }
  
  
  .mod-form__text textarea {
    width: 100%;
    height: 76px;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 11px;
    background-color: #fff;
    resize: none;
  }