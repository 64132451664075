.log-details {
	color: #000000; 
	padding: 10px; 
	border-radius: 8px;
	font-family: 'Courier New', monospace;
	font-size: 14px;
	width: 100%; 
	margin: 0; 
	box-sizing: border-box; 
	border: 1px solid #000000;
	transition: border-color 0.3s, border-width 0.3s; 
}
  
#log_details {
	width: 100%;
	height: 500px;
	border: none;
	resize: none;
	padding: 10px;
	font-family: 'Courier New', monospace;
	font-size: 14px;
	line-height: 1.6;
	box-sizing: border-box;
  }
  
.log-details:focus-within {
	border-color: #333333; 
	border-width: 2px;
}
  
.mod-btn__adminback.back {
    position: relative; 
}
.mod-btn__adminback.back img {
    position: absolute;
    left: 100%; 
    top: 50%;
    transform: translateY(-50%); 
    width: 20px; 
    margin-left: 5px;
}

.mod-btn__deletedlog.copy {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.mod-btn__deletedlog  {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: grey;
    border: #fff;
}

#icon-copy {
    width: 20px;
	margin-left: 5px;
    margin-top: 4px;
}

.mod-btn__logedit.add {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.mod-btn__logedit {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: #00CC00;
    margin-right: 10px;
    border: #fff;
}

.mod-btn__roundborderlog.add {
    width: 90px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.mod-btn__roundborderlog {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: #008eab;
    margin-right: 10px;
    border: #008eab 2px solid;
}
