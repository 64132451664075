
.btn_mailsearchback {
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100px;
    height: 30px;
    border-radius: 23px;
    background-color: #e73535;
}

.MainMail {
    width: 100%;
    height: 100%;
    padding-left: 250px;
}

.MainMail .ContentsMail {
    max-width: 90%;
    padding-top: 65px;
    padding-bottom: 38px;
    margin: 0 auto;
}
.MainEdit {
    width: 100%;
    height: 100%;
    padding-left: 250px;
}

.MainEdit .ContentEdit {
    max-width: 990px;
    padding-top: 65px;
    padding-bottom: 38px;
    margin: 0 auto;
}

.col-mail-cot1 {
    width: 18%;
}

.col-mail-cot2 {
    width: 21%;
}

.col-mail-cot3 {
    width: 17%;
}

.col-mail-cot4 {
    width: 17%;
}

.col-mail-cot5 {
    width: 17%;
}

.col-mail-cot6 {
    width: 10%;
}
