.menu-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 15px;
  background-color: white;
}
  
.footer-image {
  max-width: 100%;
  height: auto;
}

.lay-sidemenu__set {
  display: block;
  padding: 10px 20px; 
  color: black;
  text-decoration: none;
  border-bottom: 1px solid #ccc; 
}

.lay-sidemenu__set:hover {
  color: #f6ab00;
  background-color: #e1f1f7;
  text-decoration: none;
}

.navbar-brand span,
.logout-container span {
  font-weight: normal;
  color: black;
}

.lay-sidemenu__set.menu_active {
  color: blue; 
  background-color: #e0f0ff; 
  text-decoration: none;
}

/* .logout-container {
  position: absolute;
  right: 20px; 
  top: 50%;
  transform: translateY(-50%); 
  display: flex;
  align-items: center;
} */

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 3rem;
  z-index: 1404;
  background-color: #eceef1;
  box-shadow: 0 2px 10px rgba(240, 241, 240, 0.1);
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding-top: 2px; 
}

.navbar-brand {
  padding-left: 1rem;
  font-weight: bold;
}

.logout-container {
  display: flex;
  align-items: center;

}

.logout-container span {
  cursor: pointer; 
  color: black;
  font-weight: normal;
}

.container-head {
  width: 100%;
  padding-right: 15px;
  margin-right: auto;
}