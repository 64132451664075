.mod-form-maintenance__text{
    width: 100%;
    height: 38px;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
}

input[type="text"] {
    width: 100%;  
}

.col-maintenancenew-cot1 {
    width: 45%;
}
.col-maintenancenew-cot2 {
    width: 45%;
}
.col-maintenancenew-cot3 {
    width: 10%;
}