

.lay-master-set .mail-send__set {
    margin-left: 10%;
}

.lay-master-set .checkbox-frame {
    margin-left: 20px;
}

.lay-master-set__form .mail-send__set .checkbox-input {
    margin-left: 6px;
}

.mod-btn__mailedit {
    text-align: center;
    display: block;
    color: #008eab;
    font-size: 17px;
    border-radius: 23px;
    background-color: #fff;
    border: #008eab 2px solid;

}
.mod-btn__mailedit.add {
    width: 90px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}


.lay-master-set .battery-input input[type="text"] {
    margin-left: 5px;
    width: 60px;
    height: 30px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 10px;
    outline: 0;
    background-color: #fff;
    border: 1px solid #ccc;
}

.lay-master-set .mail-input dd{
    max-width: 70% !important;
}

.mail-btn {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-right: 50px; 
}

.lay-master-set .mail-btn {
    margin-left: 180px;
    width: 550px;
}

.mod-btn__roundborder.editmoresmall {
    width: 100px;
    height: 38px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.mail-btn {
    display: flex;
    justify-content: space-between; 
}



.mod-btn__admindelete.add {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}
.mod-btn__admindelete {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: #008eab;
    border: #008eab 2px solid;
}

.mod-btn__admindelete.add {
    width: 90px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.mod-btn__admindelete:disabled {
    cursor: not-allowed;
    pointer-events: none;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.mod-btn__admindelete {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: #FF0000;
    border: #fff;
}
.mod-btn__log.add {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}
.mod-btn__log {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: #00CC00;
    margin-right: 10px; 
    border: #fff;
}

.mod-btn__mailedit:disabled {
    cursor: not-allowed;
    pointer-events: none;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.mod-btn__adminback.back {
    width: 90px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}
.mod-btn__adminback {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: grey;
    border: #fff;
}



@media (max-width: 576px) {
    .lay-master-set .mail-btn {
        margin: 0;
        width: 550px;
    }

    .lay-master-set .confirm {
        margin-left: 0;
        margin-bottom: 30px;
        width: 350px;
        height: 150px;
        background: #fff;
        text-align: center;
        display: table;
        vertical-align: middle;
    }

    .lay-master-set .breakdown {
        margin-left: 40px;
        font-size: 16px;
        padding-top: 20px;
        color: #666;
    }

    .lay-master-set__form .wrapper {
        margin-left: 0;
        width: 100%;
        height: 80%;
    }

    .lay-master-set .mail-send__set {
        margin-left: 0;
    }

    .lay-master-set .mail-input .add-btn {
        margin-left: 80%;
    }
    
    .battery-input {
        display: flex;
    }

    .mail-btn button {
        width: 150px !important;
    }

    .mail-btn div:last-child{
        margin-left: 50px !important;
    }

    .lay-master-set__title{
        margin-right: 0 !important;
    }

    .lay-master-set__form .user_input {
        text-align: left;
        margin-left: 10%;
        margin-top: 20px;
    }

    .lay-master-set .user-btn {
        width: 100%;
        padding-left: 30px;
    }
}

@media (min-width: 577px) and (max-width: 767px) {
    .lay-master-set .mail-btn {
        margin: 0;
        width: 550px;
    }

    .lay-master-set .confirm {
        margin-bottom: 30px;
        width: 500px;
        height: 150px;
        background: #fff;
        text-align: center;
        display: table;
        vertical-align: middle;
    }

    .lay-master-set .breakdown {
        margin-left: 40px;
        font-size: 16px;
        padding-top: 20px;
        color: #666;
    }

    .lay-master-set__form .wrapper {
        margin-left: 0;
        width: 100%;
        height: 80%;
    }

    .battery-input {
        display: flex;
    }

    .mail-btn button {
        width: 150px !important;
    }

    .mail-btn div:last-child{
        margin-left: 50px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .lay-master-set .mail-btn {
        margin-left: 40px;
        width: 550px;
    }

    .lay-master-set .confirm {
        margin-left: 0;
        margin-bottom: 30px;
        width: 410px;
        height: 150px;
        background: #fff;
        text-align: center;
        display: table;
        vertical-align: middle;
    }

    .lay-master-set .breakdown {
        margin-left: 40px;
        font-size: 16px;
        padding-top: 20px;
        color: #666;
    }

    .lay-master-set__form .wrapper {
        margin-left: 0;
        width: 100%;
        height: 80%;
    }

    .battery-input {
        display: flex;
    }

    .mail-btn button {
        width: 150px !important;
    }

    .mail-btn div:last-child{
        margin-left: 50px !important;
    }
}

