.mod-btn__companyedit.morelong {
    text-align: center;
    display: block;
    color: #fff;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
    width: 180px;
}

.mod-btn__companyedit {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: #008eab;
    border: #fff;
}