

.col-companysearch-cot1 {
  width: 45%; 
}

.col-companysearch-cot1{
  width: 45%; 
}

.col-companysearch-cot3 {
  width: 10%; 

} 

.btn_company {
  color: #008eab; 
  line-height: 42px; 
  width: 30%; 
  height: 30px; 
  border-radius: 23px; 
  background-color: #fff;
  border: #008eab 2px solid;
  margin: auto; 
}

.btn_companysearch {
  color: #008eab;
  font-size: 17px;
  line-height: 42px;
  width: 60px;
  height: 30px;
  border-radius: 23px;
  background-color: #fff;
  border: #008eab 2px solid;
}

.popup {
	text-align: center;
	width: 80%;
	max-width: 80%;
  position: fixed; 
	left: 50%;
	top: 50%;
  transform: translate(-40%, -45%);
  position: fixed; 

}
.popup-wrapper-company {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 10;
  cursor: pointer;
}
.popup-content {
  height: auto;
  max-height: 522px; 
  text-align: left;
  background: #FFFFFF;
  border-radius: 0 0 .4rem .4rem;
}
.popup-top {
  background-color: #1E90FF;
  text-align: left;
  padding: 5px 10px;
  color: #fff;
  border-radius: .9rem .9rem 0 0; 
}

.lay-search-company__form__col3 {
    margin-bottom: 16px;
}

.lay-search-company__form__col3 ul {
  display: flex;
  align-items: center;
  gap: 40px;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap; 
}

ul {
  margin-bottom: 0;
}

.mod-form-company__set dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap; 
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 0 !important;
}

.mod-form-company__set dl dd {
  flex: 1; 
  margin: 0;
  padding: 0;
}

.mod-form-company__set dl dt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 120px;
  height: 38px;
  background-color: #777777;
  font-size: 15px;
  font-weight: normal !important;
  color: #fff;
}


.popup-close {
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
  background: #ffffff;
  color: #1E90FF;
  margin-right: -10px;
  margin-top: -10px;
  font-weight: bold;
  border-radius: 4px;
  font-size: 10pt;
  padding: 1px 5px; 
  border: 1px solid #f0f0f0;
  z-index: 1001; 
}
  
.mod-form-company__text input[type="text"]::-webkit-input-placeholder {
  color: #9FA0A3;
}

.mod-form-company__text input[type="text"]::-moz-placeholder {
  color: #9FA0A3;
}

.mod-form-company__text input[type="text"]:-ms-input-placeholder {
  color: #9FA0A3;
}

.lay-search-wrap-company {
  padding: 10px 45px 45px 45px; 
	/* background-color: #dcf1fa; */
	border-radius: 26px;
}

.btn_SearchCompany {
  transform: translateX(-10px);
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  width: 100px;
  height: 35px;
  border-radius: 5px;
  background-color: #5F5FF8;
}

.mod-btn__SearchCompany {
  display: inline-block;
  margin: auto;
  color: #fff;
  border-radius: 5px;
  background-color: #5F5FF8;
  width: 100%;
  max-width: 60px;
  height: 30px;
  font-size: 16px;
}