.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(180, 176, 176, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
  
.modal-content {
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #c5dbec;
    width: 400px;
    height: auto;
    padding: 10px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom : 10%;
}

.modal-text {
    width: 100%;
    margin-top: 30px;
    color: #555;
    font-weight: 400;
    font-size: 15px;
    white-space: pre-line;
}
  
.modal-close {
    position: absolute;
    top: 2px;
    right: 8px;
    cursor: pointer;
    font-size: 12px;
    background-color: #d6e3ed;
    width: 20px;
    height: 20px;
    text-align: center;
    color: #2e6e9e;
    border-radius: 4px;
    border: 1px solid #c5dbec;
    margin-top: 5px;
}

.modal-divider {
    width: 100%;
    margin: 5px 0;
    border: 0;
    border-top: 1px solid #a6c9e2;
}

.btn-modal {
    display: flex;
}

.btn-modal-close {
    width: 80px;
    height: 30px;
    background: #888;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #c5dbec;
}

.modalMessage{
    white-space: pre-line;
}
