.mod-form__text_adminedit textarea {
    width: 500px;
    height: 76px;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 11px;
    background-color: #fff;
    resize: none;
}

.checkbox-parts {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
}

.checkbox-input:checked + .checkbox-parts::after {
    content: "";
    display: block;
    position: absolute;
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border-style: solid;
    border-color: #09ad7e;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.mod-form-adminedit__text input[type="text"] {
    width: 100%;
    max-width: 450px;
    min-width: 450px;
    height: 38px;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
}

.mod-form-adminedit__set dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap; 
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 0 !important;
  }
  
  .mod-form-adminedit__set dl dt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 120px;
    height: 38px;
    background-color: #777777;
    font-size: 15px;
    font-weight: normal !important;
    color: #fff;
  }

  .mod-form-adminedit__text input[type="text"] {
    width: 100%; 
    max-width: 500px;
    min-width: 500px; 
    height: 38px;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
  }
  .mod-form-adminedit1__text input[type="text"] {
    width: 100%; 
    max-width: 50px;
    min-width: 50px; 
    height: 38px;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
  }

  .mod-form-adminedit__text input[type="text"]::-webkit-input-placeholder {
    color: #9FA0A3;
  }
  
  .mod-form-adminedit__text input[type="text"]::-moz-placeholder {
    color: #9FA0A3;
  }
  
  .mod-form-adminedit__text input[type="text"]:-ms-input-placeholder {
    color: #9FA0A3;
  }

  .mod-form-adminedit__text {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    padding-right: 10px; 
}

  .mod-btn__adminname {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: #008eab;
    border: #008eab 2px solid;

}

.mod-btn__adminname.add {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}
.mod-btn__adminedit.morelong {
  text-align: center;
  display: block;
  color: #fff;
  height: 35px;
  font-size: 15px;
  line-height: 30px;
  border-radius: 25px;
  width: 180px;
}

.mod-btn__adminedit {
  text-align: center;
  display: block;
  color: #fff;
  font-size: 17px;
  line-height: 42px;
  width: 100%;
  height: 45px;
  border-radius: 23px;
  background-color: #00CC00;
  border: #fff;
}
